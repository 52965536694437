<template>
    <v-container>
        <v-card>
            <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold">Reportería baja cuantía</h2>
            </div>

            <v-divider />

            <div class="px-4 py-4" style="position: relative;">
                <v-expansion-panels class="mt-4" v-model="panel">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="d-flex align-center" style="gap: 8px;">
                                <v-icon>mdi-filter-settings</v-icon>
                                <span class="text-subtitle-1 font-weight-bold text-uppercase">Filtros</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-divider />
                        <v-expansion-panel-content>
                            <div class="px-4 py-4">
                                <v-row dense align="center">
                                    <v-col cols="12" lg="4">
                                        <ProgresiveLoadAutocomplete
                                            v-model="filtros.institucion"
                                            :pageable="instituciones"
                                            :loading="instituciones.isLoading"
                                            :disabled="resumenInstituciones.isLoading"
                                            class="flex-grow-1 flex-shrink-1" 
                                            style="min-width: 200px;"
                                            label="Institución"
                                            placeholder="Seleccione una institución"
                                            itemText="nombre"
                                            itemValue="id"
                                            hide-details
                                            @search="buscarInstitucion"
                                            @load-more="cargarMasInstituciones"
                                            clearable
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <v-select 
                                            v-model="filtros.anio"
                                            outlined 
                                            hide-details 
                                            :items="aniosFiscales.data"
                                            :loading="aniosFiscales.isLoading"
                                            :disabled="resumenInstituciones.isLoading"
                                            item-value="id"
                                            item-text="anio"
                                            label="Año"
                                            placeholder="Seleccione un año"
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <div class="d-flex flex-wrap align-center justify-center" style="gap: 8px;">
                                            <v-btn 
                                                class="flex-grow-1 flex-shrink-1" 
                                                :disabled="resumenInstituciones.isLoading"
                                                x-large 
                                                outlined 
                                                color="primary"
                                                @click.stop="limpiarFiltros"
                                            >
                                                Limpiar
                                            </v-btn>
                                            <v-btn 
                                                class="flex-grow-1 flex-shrink-1" 
                                                :loading="resumenInstituciones.isLoading"
                                                x-large 
                                                color="primary"
                                                @click.stop="buscar"
                                            >
                                                Buscar
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <EstadisticasAnuales 
                    class="mt-4"
                    :filtros="filtrosActivos"
                    :nombre-anio="nombreAnio"
                />

                <DataTableComponent 
                    :items="resumenInstituciones.data"
                    :headers="headers"
                    :total_registros="totalRegistros"
                    :show_loading="resumenInstituciones.isLoading"
                    v-models:pagina="paginacion.pagina"
                    v-models:select="paginacion.registrosPorPagina"
                    dense
                    no-gutters
                    class="mt-4"
                    @paginar="manejarPaginacion"
                    @sortFunction="ordenarPorColumna"
                >
                    <template v-slot:item.institucion="{ item }">
                        <b class="mr-1">{{ item.codigo }} -</b>
                        {{ item.nombre }}
                    </template>
                    <template v-slot:item.monto_reservado="{ item }">
                        {{  toMoneyFormat(item.monto_reservado)  }}
                    </template>
                    <template v-slot:item.monto_ejecutado="{ item }">
                        {{  toMoneyFormat(item.monto_ejecutado)  }}
                    </template>
                    <template v-slot:item.detalles="{ item }">
                        <v-btn @click.stop="verDetalleInstitucional(item)" icon>
                            <v-icon color="secondary">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </DataTableComponent>
            </div>
        </v-card>

        <ModalFondosBajaCuantia 
            :is-open.sync="modalVerDetalleAbierta" 
            :institucion="filaActiva?.id"
            :anio="filtrosActivos.anio"
        />
    </v-container>
</template>
<script>
import { createPageable, createLoadable, isResponseSuccesful, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import ProgresiveLoadAutocomplete from '@/components/utils/ProgresiveLoadAutocomplete.vue';
import ModalFondosBajaCuantia from './components/bajaCuantia/ModalFondosBajaCuantia.vue';
import EstadisticasAnuales from './components/bajaCuantia/EstadisticasAnuales.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import { toMoneyFormat } from '@/utils/data';

export default {
    name: 'ReporteBajaCuantia',
    components: { NavButton, DataTableComponent, ProgresiveLoadAutocomplete, ModalFondosBajaCuantia, EstadisticasAnuales },
    data: () => ({
        // UI
        panel: 0,
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
        paginacionInstituciones: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        filtros: {
            institucion: null,
            anio: null,
        },
        busquedas: {
            institucion: null,
        },
        filtrosActivos: {
            institucion: null,
            anio: null,
        },
        filaActiva: null,
        modalVerDetalleAbierta: false,
        sorter: {
            column: null,
            order: null,
        },
        // DATA
        resumenInstituciones: createPageable([], 10),
        instituciones: createPageable([], 30),
        aniosFiscales: createLoadable([]),
    }),
    computed: {
        headers() {
            return [
                { align: 'center', sortable: true, text: 'Institución', value: 'institucion' },
                { align: 'center', sortable: true, text: 'Monto reservado', value: 'monto_reservado' },
                { align: 'center', sortable: true, text: 'Monto ejecutado', value: 'monto_ejecutado' },
                { align: 'center', sortable: false, text: 'Detalles', value: 'detalles' },
            ];
        },
        totalRegistros() {
            return this.resumenInstituciones.pagination.total_rows;
        },
        nombreAnio() {
            const anio = this.aniosFiscales.data.find((anio) => anio.id === this.filtrosActivos.anio);

            return anio?.anio;
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina }

            this.cargarResumenInstituciones();
        },
        verDetalleInstitucional(institucion) {
            this.filaActiva = { ...institucion };
            this.modalVerDetalleAbierta = true;
        },
        ordenarPorColumna({ sortBy, sortDesc }) {
            this.sorter = {
                column: sortBy[0],
                order: sortDesc[0] ? 'DESC' : 'ASC',
            }

            this.paginacion.pagina = 1;
            this.cargarResumenInstituciones();
        },
        // DATA
        async cargarResumenInstituciones() {
            const filtros = {
                pagination: true,
                per_page: this.paginacion.registrosPorPagina,
                page: this.paginacion.pagina,
                id_institucion: this.filtros.institucion,
                id_anio: this.filtros.anio,
                ...this.sorter.column && ({ ordenar_por: this.sorter.column, orden: this.sorter.order }),
            }

            togglePageable(this.resumenInstituciones);
            const { data, headers } = await this.services.ReporteriaBajaCuantia.cargarResumenInstituciones(filtros);
            setPageableResponse(this.resumenInstituciones, data, headers);
        },
        // Instituciones
        async buscarInstitucion(termino) {
            if (this.busquedas.institucion === termino) return;

            this.busquedas.institucion = termino;
            this.instituciones.data = [];
            this.paginacionInstituciones.pagina = 1;

            this.cargarInstituciones();
        },
        cargarMasInstituciones() {
            const { page, per_page, total_rows } = this.instituciones.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionInstituciones.pagina = page + 1;
            this.paginacionInstituciones.registrosPorPagina = per_page;

            this.cargarInstituciones();
        },
        async cargarInstituciones() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionInstituciones.registrosPorPagina,
                page: this.paginacionInstituciones.pagina,
                busqueda: this.busquedas.institucion,
                excluir_centros_escolares: true,
            };

            togglePageable(this.instituciones);
            const { data, headers } = await this.services.BajaCuantia.cargarInstituciones(filtros);   

            const copiaInstituciones = this.instituciones.data;
            setPageableResponse(this.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                const { pagina } = this.paginacionInstituciones;
                this.paginacionInstituciones.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.instituciones.data = copiaInstituciones.concat(data.data);
        },
        // Filtro anio
        async cargarAniosFiscales() {
            toggleLoadable(this.aniosFiscales);
            const { data } = await this.services.PacProcesos.cargarAniosFiscales();
            setLoadableResponse(this.aniosFiscales, { data: Array.isArray(data) ? data : [] });

            return data;
        },
        // Botones de filtros
        buscar() {
            this.paginacion.pagina = 1;
            this.filtrosActivos = { ...this.filtros };
            this.filaActiva = null;

            this.cargarResumenInstituciones();
        },
        limpiarFiltros() {
            const anioActual = (new Date()).getFullYear();
            const anioFiscal = this.aniosFiscales.data.find((anio) => anio.anio === anioActual);

            this.paginacion.pagina = 1;
            
            this.filtros = {
                institucion: null,
                anio: anioFiscal.id,
            }

            this.filtrosActivos = { ...this.filtros };
            this.filaActiva = null;

            this.busquedas = {
                institucion: null,
            }

            this.paginacionInstituciones.pagina = 1;

            this.instituciones = createPageable([], 30);
            this.cargarInstituciones();
            this.cargarResumenInstituciones();
        },
    },
    created() {
        this.cargarResumenInstituciones();
        this.cargarAniosFiscales().then((anios) => {
            const anioActual = (new Date()).getFullYear();

            const anioFiscal = anios.find((anio) => anio.anio === anioActual);

            this.filtros.anio = anioFiscal.id;
            this.filtrosActivos = { ...this.filtrosActivos, anio: anioFiscal.id };
            this.cargarInstituciones();
        });
    },
}
</script>
<style scoped>
    :deep(.v-expansion-panel-content__wrap) {
        padding: 0px !important;
    }
</style>